@import "../../../common/styles/common.scss";
@import "../../../common/styles/colors.scss";

.plan-memento
{
    background-color: $section-background-color;
    margin: 5px;
    border-radius: $border-radius-big-element;
    padding: 10px;
    border: 5px solid $page-background-color;
}

.plan-memento-header
{
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .plan-memento-heading
    {
        align-self: center;
        color: $paragraph-text-color;
        font-weight: bold;
    }

    img
    {
        width: 50px;
    }
}

.plan-memento-body
{
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .plan-memento-text
    {
        background-color: $page-background-color;
        padding: 10px;
        width: 70%;
        border-radius: $border-radius-big-element;
        
        textarea
        {
            color: $paragraph-text-color;
            font-family: $font-family-common;
            text-align: center;
        }
    }

    .plan-memento-save-button
    {
        background-color: $active-elements-color;
        padding: 7px 7px;
        border-radius: $border-radius-big-element;
        font-size: 12px;
        width: 45px;
        height: 50%;
        align-self: center;
        color: $section-background-color;
    }

    .plan-memento-save-button:hover
    {
        cursor: pointer;
    }
}

.plan-no-mementos-p
{
    text-align: center;
}