$primary_color: #94C11E;
$primary_color_lightest: lighten($primary_color, 40);
$primary_color_lighter: lighten($primary_color, 30);
$primary_color_darker: darken($primary_color, 5);
$complementary_color: white;
$complementary_color_darker: darken($complementary_color, 8);
$complementary_color_darkest: darken($complementary_color, 40);

/*
NEW
*/
$active-elements-color: #95C11F;
$section-background-color: #FFFFFF;
$page-background-color: #F2F2F2;
$heading-text-color: #333333;
$paragraph-text-color: #707070;
$contrast-to-page-background-color: #DDDDDD

