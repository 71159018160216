@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.page-wrapper
{
    padding-top: $navbar-height;
    min-height: 100%;
    background-color: $page-background-color;
    //color: #696969;
    cursor: default;
}