@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.profil-wrapper
{
    @include current-page-wrapper;
}

.profil-content
{
    @include current-page-content;
}

.profil-content-panels
{
    @include current-page-content-panels;
}

.profil-content-panel-1
{
    @include current-page-content-panel-element;
}

.profil-content-panel-2
{
    @include current-page-content-panel-element;
}

.profil-personal-info-processing
{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $section-background-color;
    border-radius: $border-radius-big-element;
    padding: 10px 10px 0 10px;
    width: 100%;
    min-height: 149px;
    @include plastic-shadow;
}

.profil-settings-list
{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $section-background-color;
    border-radius: $border-radius-big-element;
    padding: 10px;
    width: 100%;
    @include plastic-shadow;

    .profil-settings-list-item
    {
        width: 200px;
        margin: 5px 0 5px 0;

        .profil-settings-option
        {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .list-item-img
        {
            width: 40px;
            margin-right: 10px;
        }
    }

    .list-item-span
    {
        color: $paragraph-text-color;
        font-weight: bold;
    }

    .list-item-span:hover
    {
        background-color: $active-elements-color;
        border-radius: $border-radius-big-element;
        padding: 5px;
        cursor: pointer;
        color: $section-background-color;
    }
}

.profil-span-title
{
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    cursor: default;
}

.profile-strength-indicator
{
    background-color: $section-background-color;
    text-align: center;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: $border-radius-big-element;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include plastic-shadow;

    .profile-strength-indicator-title
    {
        font-size: 20px;
        font-weight: bold;
        padding: 10px;
        cursor: default;
    }

    .profile-strength-indicator-input
    {
        width: 90%;
        cursor: default;
    }

    .profile-strength-indicator-input::-webkit-slider-runnable-track
    {
        background: linear-gradient(90deg, rgba(226,29,29,1) 0%, rgba(228,225,63,1) 50%, rgba(166,255,0,1) 100%);
        border-radius: 5px;
        cursor: default;
    }

    .profile-strength-indicator-input::-webkit-slider-thumb
    {
        background: $section-background-color;
        border: 1px solid #333333;
        cursor: default;
    }
}

.profil-survey-list
{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $section-background-color;
    border-radius: $border-radius-big-element;
    width: 100%;
    //margin-top: 10px;
    //padding: 10px;
    @include plastic-shadow;

    .profil-survey-list-item
    {
        //margin: 5px 0 5px 0;
        .profil-survey-button
        {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 45px;
            width: 420px;
            margin: 5px 0 5px 0;

            .profil-survey-img
            {
                width: 40px;
                margin: 0 10px 0 10px;
            }

            .profil-survey-title
            {
                color: $paragraph-text-color;
            }
            
            .profil-survey-title:hover
            {
                cursor: pointer;
                padding: 5px 7px 5px 7px;
                background-color: $active-elements-color;
                border-radius: $border-radius-big-element;
                color: $section-background-color;
            }
        }
    }
}

//--------------------------------------------------


.survey-detail-form {
  @include pop-up-window;

  .validation-message {
    color: red;
    font-size: 15px;
    margin-bottom: 5px;
    text-align: right;
  }

  .lastOptionSimpleInput {
    margin: 0px 20px
  }

  textarea {
    margin: 0px 20px;
  }

  input[type=text], input[type=number], textarea {
    background-color: $contrast-to-page-background-color;
    border-radius: $border-radius-big-element;
    padding: 5px 10px;
    margin-bottom: 15px;
    max-width: 87%
  }

  .cancel-button {
    @include pup-up-cancel-button;

    img {
      height: 30px;
      width: 30px;
    }
  }

  .detail-image {
    text-align: center;
    //height: 300px;
    //width: 300px;
    max-width: 300px;
    max-height: 300px;
  }

  form {    
    @include flex-direction(column);

    img {
      align-self: center;
    }
  }

  .survey-detail-footer {
    @include flex-direction-justify(row, space-between);
    align-items: center;

    button {
      padding: 7px 25px;
      background-color: $active-elements-color;
      color: $section-background-color;
      border-radius: $border-radius-big-element;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }
}


input[type=range] {
  height: 28px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  background: $complementary_color_darkest;
  border-radius: 1px;
}
input[type=range]::-webkit-slider-thumb {
  height: 20px;
  width: 21px;
  border-radius: 15px;
  background: $complementary_color;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  background: $complementary_color_darkest;
}
input[type=range]::-moz-range-thumb {
  height: 20px;
  width: 21px;
  border-radius: 15px;
  background: $complementary_color;
  cursor: pointer;
}