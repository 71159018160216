@import "./common/styles/common.scss";
@import "./common/styles/colors.scss";

body {
  font-family: 'Roboto', sans-serif;
}

*, body, html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  //Vojta
  max-width: 100%;
}

html, body, #root {
  height: 100%;
}

.app-small-screen-window {
  flex-direction: column;
  z-index: 100;  
  position: fixed;
  align-items: center;
  background-color: white;
  color: black;
  padding: 20px 35px;
  top: 50%;
  left: 50%;
  transform: translateY(-55%) translateX(-50%);
  max-width: 400px;
  border: 1px solid black;
  border-radius: $border-radius-big-element;
  font-size: 17px;  
  overflow-y: auto;
  max-height: 80%;

  .app-small-button {
    padding: 6px 30px;
    background-color: $primary_color;
    color: $complementary_color;
    border-radius: 10px;
    margin-top: 25px;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 10px;
  }

  a {
    color: blue;
  }

  img {
    width: 45%;
    height: auto;
  }

  .cancel-button {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;

    img {
      height: 30px;
      width: 30px;
    }
  }
}

#app-offline-notice {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: lighten(yellow, 10);
  border: 1px solid black;
  text-align: center;
  padding: 20px 10px;
}