@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";
  
.home-wrapper
{
    @include current-page-wrapper;
}

.home-content
{
    @include current-page-content;
}

.home-content-panels
{
    @include current-page-content-panels;
}

.home-content-panel-1, .home-content-panel-2
{
    @include current-page-content-panel-element;
}

h1
{
    @include current-page-heading;
}

.widget-statistics-wrapper, .widget-memento-wrapper
{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.widget-appointment-wrapper
{
    text-align: center;
    font-size: 18px;
}

.widget-badges-wrapper
{
    width: 480px;
}

@media screen and (min-width: 1250px) {
  .widget-badges-wrapper
  {
      width: 980px;
  }
}

.widget-memento-text
{
    overflow-x: scroll;
    max-width: 100%;
}

.home-item-badge {
  @include flex-direction-align(column, center);  
  background-color: $primary_color_darker;
  color: $complementary_color;
  border-radius: 10px;
  padding: 10px;
  margin-top: 12px;
}

.home-item-plan {
  background-color: $primary_color_darker;
  color: $complementary_color;
  padding: 7px 25px;
  border-radius: 10px;
  margin-top: 5px;
}

.to_appointment_link
{
  background-color: $active-elements-color;
  padding: 7px;
  border-radius: $border-radius-big-element;
  color: $section-background-color;
  width: 130px;
  margin: 10px auto;
}

.home-message-container {
  @include flex-direction-align-justify(row, center, center);  
  margin-top: 20px;
  color: black;
  
  img {
    width: 75px;
  }

  .home-message-content {
    width: 500px;
    // background-color: $complementary_color;
    @include flex-direction-align-justify(row, center, center);  

    .home-message-right {
      max-width: 300px;
      margin-left: 15px;
    }

    button {
      background-color: $complementary_color;
      text-transform: uppercase;
      padding: 10px 20px;
      border-radius: 15px;
      cursor: pointer;
    }
  
    .home-message-full-price {
      text-decoration: line-through;
    }

    .home-message-time {
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
    }
  
    .home-message-discount-price {
      color: $primary_color;
      font-weight: bold;
    }

    .home-message-time-left {
      color: red;
    }
  }
}
