@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.hint-wrapper
{
    display: grid;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 20px;
}

.show-button
{
    display: block;
    font-size: 28px;
    background-color: $active-elements-color;
    width: 40px;
    height: 40px;
    border-radius: $border-radius-big-element;
    color: $section-background-color;
    cursor: pointer;
}

.show-button:hover
{
    background-color: #E6E200;
}

.hide-button
{
    display: block;
    margin: 0 auto;
    padding: 10px 10px 10px 10px;
    border-radius: $border-radius-big-element;
    //border-style: solid;
    //border: 1px solid gray;
    cursor: pointer;
    background-color: $active-elements-color;
    font-size: 16px;
    color: $section-background-color;
}

.hint-p
{
    max-width: 90%;
    font-size: 14px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
    background-color: $page-background-color;
    //text-align: justify;
    padding: 15px 10px 15px 10px;
    border-radius: $border-radius-big-element;
    color: $paragraph-text-color;
}

.hint-container
{
    display: none;
}