@import "../../../common/styles/common.scss";
@import "../../../common/styles/colors.scss";

.badges-wrapper-list
{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;

    .badge-li
    {
        min-width: 110px;
        margin: 0 5px 0 5px;
    }
}