@import "../../../common/styles/common.scss";
@import "../../../common/styles/colors.scss";

.app-img {
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: center;
}

.header-no-border {
  @extend .app-img;
	max-width: 80%;
}

.round-icon {
  @extend .app-img;
	max-width: 20%;
}

.appointment-wrapper {
  align-items: center;
  /*
  li {
    margin-left: 25px;
  }
  
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  textarea, input[type=text] {
    width: 100%;
  }

  h1 {
    font-size: 40px !important;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 32px !important;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  */

  .margin-bottom-element {
    height: 10px;
    width: 10px;
  }

  .toggle-content {
    padding-bottom: 20px;
    max-width: 400px;

    &>p:last-child {
      margin-bottom: 0px;
    }
  }

  blockquote {
    background-color: $page-background-color;
    color: $paragraph-text-color;
    padding: 10px;
    border-radius: $border-radius-big-element;
    width: fit-content;
    max-width: 600px;
    margin: 10px auto;
    
    p {
      width: 100%;
    }
  }

  ol, ul {
    margin-bottom: 10px;
    padding: $padding-big-element;
    text-align: left;
    max-width: 600px;
    list-style: none;
  }
}

.app-text {
  font-size: 22px;
}

.app-input {
  font-size: 20px;
  border-radius: $border-radius-big-element;
  background-color: $page-background-color;
  padding: 10px;
  font-family: $font-family-common;
  text-align: center;
}

.app-input-container {
  margin-bottom: 10px;
}
  
.el-strong {
  font-weight: bold;;
}

.el-cursive {
  font-style: italic;
}

.appointment-text-container {
  font-size: 20px !important;
}

.button-next-page {
  margin: 0px auto;
  margin-top: 20px;
  border-radius: 30px;
  background-color: $complementary_color_darker;
  padding: 8px 15px;
  cursor: pointer;

  &:disabled {
    cursor: initial;
  }
}

.toggle-container {
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $page-background-color;
  padding: 10px;
  border-radius: $border-radius-big-element;
}

.toggle-headline {
  @include flex-direction-align(row, center);
  text-align: center;
  

  p {
    margin-bottom: 0px;
    margin-top: 0px;
    min-width: fit-content;
  }

  img {
    width: 80px;
  }
  
  button {
    @include flex-direction-align(row, center);
    color: $active-elements-color;
    cursor: pointer;
    min-width: 400px;

    .button-sign {
      background-color: $active-elements-color;
      color: $section-background-color;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      padding: 0px;
      margin-right: 5px;
      margin-left: 5px;
      text-align: center;
      font-size: 20px;
      line-height: 30px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }
}

.multiplechoice {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: $page-background-color;
  border-radius: $border-radius-big-element;
  padding: $padding-big-element;
  margin-bottom: 10px;
  max-width: 600px;

  p {
    width: auto;
  }

  div {
    max-width: 400px;
  }
}

/*
.appointment {
  .multiplechoice {

    &>div:first-child {
      padding-left: 10px;
    }

    input {
      margin-left: 10px;
    }
  }
}
*/

.multiplechoice-title {
  margin-bottom: 10px;
}

.multiplechoice-alternative {
  @include flex-direction-align(row, center);
  margin-bottom: 15px;
  margin-left: 15px;
  text-align: left;

  input {
    margin-right: 10px;
    transform: scale(1.5);
  }

  input, label {
    cursor: pointer;
  }

  p {
    margin: 0px;
    width: auto;
  }
}

.multiplechoice-toggle-alternative {
  @include flex-direction-align(row, center);
  margin-bottom: 5px;

  input {
    margin-right: 10px;
    transform: scale(1.4);
  }

  p {
    margin: 0px;
  }
}

.multiplechoice-toggle-alternative-text {
  margin-left: 35px;
  margin-bottom: 10px;
  font-size: 14px;
  max-width: 400px;

  p {
    margin: 0px;
  }
}

.element-validation-message {
  font-size: 14px;
  margin-top: 5px;
  color: red;
}