@import "../../../common/styles/common.scss";
@import "../../../common/styles/colors.scss";

.rewards-list
{
    list-style: none;
    width: 480px;
    height: 370px;
    background-color: $section-background-color;
    padding: 10px;
    border-radius: $border-radius-big-element;
    margin-bottom: 15px;
    justify-content: center;
    @include plastic-shadow;

    .rewards-list-title
    {
        font-size: 26px;
        font-weight: bold;
        margin: 10px 0 40px 80px;
    }
}