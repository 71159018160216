@import "./colors";

$navbar-height: 80px;

@mixin current-page-wrapper
{
    display: flex;
    justify-content: center;

    @include custom-scrollbar;
}

@mixin current-page-content
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 1400px;
    padding: 20px 0 20px 0;
    margin: 20px 0 75px 0;
    border-radius: $border-radius-big-element;
    background-color: $page-background-color;

  }
  
@mixin current-page-background-image
{
    width: 100%;
    border-radius: $border-radius-big-element;
    @include plastic-shadow;
}

@mixin current-page-content-panels
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    //padding-bottom: 25px;
}

@mixin current-page-content-panel-element
{
    display: flex;
    flex-direction: column;
    width: 500px;
    align-items: center;
    padding: 10px 10px 0 10px;
}

@mixin current-page-heading
{
    text-align: center;
    padding-bottom: 15px;
    color: $paragraph-text-color;
}

@mixin pop-up-window
{
    position: fixed;
    top: 15%;
    left: calc(50% - 200px);
    background-color: $section-background-color;
    padding: $padding-big-element;
    border-radius: $border-radius-big-element;
    width: 400px;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    z-index: 100;
    @include custom-scrollbar;
}

@mixin pup-up-cancel-button
{
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 15px;

    img
    {
        width: 40px;
        height: 40px;
    }
}

@mixin plastic-shadow
{
  filter: drop-shadow(5px 2px 5px lightgray);
}

@mixin custom-scrollbar
{
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: transparent $active-elements-color;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $active-elements-color;
    border-radius: 10px;
    border: 3px solid transparent;
  }

  *::-webkit-scrollbar-button
  {
    display: none;
  }
}

$padding-big-element: 15px;
$border-radius-big-element: 25px;
$font-family-common: Arial, Helvetica, sans-serif;
$font-size-h2: 25px;

//----------------------------------------------------------------

@mixin flex-direction-align-justify($dir, $align, $justify) {
  display: flex;
  flex-direction: $dir;
  align-items: $align;
  justify-content: $justify;
}

@mixin flex-direction-align($dir, $align) {
  display: flex;
  flex-direction: $dir;
  align-items: $align;
}

@mixin flex-direction-justify($dir, $justify) {
  display: flex;
  flex-direction: $dir;
  justify-content: $justify;
}

@mixin flex-direction($dir) {
  display: flex;
  flex-direction: $dir;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 18px;
}

.next-url {
  @include flex-direction-align(row, center);
  border: 2px solid blue;
  min-height: 95px;
  font-size: 20px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: white;
  text-align: center;
  
  img {
    height: 100px;
  }

  a, button {
    cursor: pointer;
    padding: 20px 30px;
    margin-right: 15px;
  }
}

.loading-spinner {
  display: inline-block;
  width: 70px;
  height: 70px;
  margin-top: 10px;
  border: 6px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}