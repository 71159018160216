@import "../../common/styles/colors.scss";
@import "../../common/styles/common.scss";

.randomtip-wrapper
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.randomtip-heading
{
    font-size: 28px;
    font-weight: bold;
    color: $heading-text-color;
    display: block;
    //padding: 10px;
    text-align: center;
}

.randomtip-next
{
    background-color: $active-elements-color;
    padding: 10px;
    border-radius: $border-radius-big-element;
    display: block;
    margin: 15px 0 10px 0;
    color: $section-background-color;
    width: 120px;
}

.randomtip-next:hover
{
    cursor: pointer;
}

.randomtip-text
{
    font-size: 16px;
    background-color: $page-background-color;
    color: $paragraph-text-color;
    padding: 10px 15px 10px 15px;
    border-radius: $border-radius-big-element;
    display: block;
    margin: 5px;
    overflow-y: scroll;
}