@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";
  
.Onboarding {
  //background: linear-gradient($primary_color_lightest, $primary_color);
  background-color: $primary_color_lighter;
  padding: 0px 10px;

  input[type="radio"] {
    margin-left: 20px;
    transform: scale(1.2);
    cursor: pointer;
  }

  label {
    margin-left: 10px;
    cursor: pointer;
  }

  .logo-black {
    width: 100px;
    height: auto;
  }

  .onboarding-welcome-adam {
    margin-right: 50px;
    max-width: 90%;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
  
.Onboarding-header {
  @include flex-direction(row);
}

.onboarding-container {
  @include flex-direction-justify(row, center);
}

@keyframes message-fade-in1 {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
@keyframes message-fade-in2 {
  0%{opacity: 0;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}
@keyframes message-fade-in3 {
  0%{opacity: 0;}
  75%{opacity: 0;}
  100%{opacity: 1;}
}
@keyframes message-fade-in4 {
  0%{opacity: 0;}
  80%{opacity: 0;}
  100%{opacity: 1;}
}
@keyframes message-fade-in5 {
  0%{opacity: 0;}
  83%{opacity: 0;}
  100%{opacity: 1;}
}

.message-block-invisible {
  opacity: 0;
}
.message-animation1 {
  animation-name: message-fade-in1;
  animation-duration: 1s;
}
.message-animation2 {
  animation-name: message-fade-in2;
  animation-duration: 2s;
}
.message-animation3 {
  animation-name: message-fade-in3;
  animation-duration: 3s;
}
.message-animation4 {
  animation-name: message-fade-in4;
  animation-duration: 4s;
}
.message-animation5 {
  animation-name: message-fade-in5;
  animation-duration: 5s;
}

.small-input {
  width: 60px;
  margin-right: 10px;
}

.message-block-right input[type="text"], .message-block-right input[type="password"] {
  background: $complementary_color;
  margin-left: 15px;
  border-radius: 3px;
  padding: 2px 5px;
}

.img-container {
  @include flex-direction-align-justify(row, center, center);
  width: 40%;
  position: relative;
}

.spinner {
  @include flex-direction-align-justify(column, center, center);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($complementary_color_darker, 0.8);
  color: $primary_color;

  .spinner-text {
    position: relative;
    font-size: 80px;
    color: black;
  }
  
  .loading-spinner {
    border: 6px solid gray;
    border-top-color: black;
  }
}

.form-input {
  display: inline-block;
}

.range-form-container {
  @include flex-direction-align(column, center);
}

.range-form {
  @include flex-direction-align(row, center);
}

@media (max-width: 1450px) {
  .responsive-form-row {
    width: 400px;
  }
  .form-input:first-child {
    margin-bottom: 20px;
  }
}

.message-container {
  display: flex;
  flex-direction: column;
  width: 42%;
}

.img-circle {
  position: absolute;
  border: 1px solid $complementary_color;
  border-radius: 100%;
}

.img-circle-right-normal {
  @extend .img-circle;
  width: 40%;
  padding-top: 40%;
  overflow: hidden;
  right: 30%;
  bottom: 10%;

  img {
    position: absolute;
    width: 80%;
    left: 8%;
    bottom: 8%;
  }
}

.img-circle-right-small {
  @extend .img-circle;
  width: 15%;
  padding-top: 15%;
  bottom: 5%;
  right: 12.5%;
}

.img-circle-right-smallest {
  @extend .img-circle;
  width: 7%;
  padding-top: 7%;
  bottom: 2%;
  right: 2%;
}

.img-circle-left-normal {
  @extend .img-circle;
  width: 60%;
  padding-top: 60%;
  overflow: hidden;
  left: 30%;
  bottom: 10%;

  img {
    position: absolute;
    width: 90%;
    left: 5%;
    bottom: 7%;
    // bottom: -50%;
    // left: 8%;
    // transform: translate(50%);
  }
}

.img-circle-left-small {
  @extend .img-circle;
  width: 18%;
  padding-top: 18%;
  bottom: 5%;
  left: 10%;
}

.img-circle-left-smallest {
  @extend .img-circle;
  width: 5%;
  padding-top: 5%;
  bottom: 2%;
  left: 0%;
}

.message-cigarettes-top-margin {
  margin-top: 30px;
  margin-bottom: 5px;
}

.message-cigarettes-bottom-margin {
  margin-top: 5px;
  margin-bottom: 40px;
}

.onboarding-container-end {
  @include flex-direction-align(column, center);
  margin-top: 20px;
}

.final-button {
  font-weight: bold;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: $active-elements-color;
  padding: 10px;
  border-radius: $border-radius-big-element;
  width: 300px;
  color: $section-background-color;
}

.validation-box {
  color: red;  
  margin-bottom: 50px;
}

.range-input::-moz-range-track {
  background: $complementary_color !important;
  border-radius: 10px;
}

.range-input::-webkit-slider-runnable-track {
  background: $complementary_color !important;
  border-radius: 10px;
}
.range-input::-moz-range-thumb {
  border: 1px solid black;
}
.range-input::-webkit-slider-thumb {
  border: 1px solid black;
}