@import "../../../common/styles/common.scss";
@import "../../../common/styles/colors.scss";

.widget-wrapper
{
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    background-color: $section-background-color;
    padding: 10px;
    border-radius: $border-radius-big-element;
    margin-bottom: 15px;
    @include plastic-shadow;

    .widget-top-area
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        padding-bottom: 10px;
        padding-top: 10px;
        position: relative;
        top: 0;        

        .widget-title
        {
            padding: 3px;
            font-size: 20px;
            font-weight: bold;
        }
        
        .widget-link
        {
            background-color: $active-elements-color;
            padding: 7px;
            border-radius: $border-radius-big-element;
            color: $section-background-color;
        }
    }

    .widget-small-img
    {
        width: 45px;
        margin-right: 10px;
    }

    .widget-big-img
    {
        border-radius: $border-radius-big-element;
        margin: 20px 0 60px 0;
    }

    .widget-content-wrapper
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        color: $paragraph-text-color;
    }
}