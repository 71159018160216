@import "../../common/styles/colors.scss";
@import "../../common/styles/common.scss";

.help-wrapper
{
    @include current-page-wrapper;
}

.help-content
{
    @include current-page-content;
}

.help-background-image
{
    @include current-page-background-image;
}

.help-content-panels
{
    @include current-page-content-panels;
}

.help-content-panel-1
{
    @include current-page-content-panel-element;
}

.help-content-panel-2
{
    @include current-page-content-panel-element;
}

h1
{
    @include current-page-heading;
}

.help-li-img
{
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 10px;
}

.help-options-list
{
    list-style-type:none;
    display:grid;
    justify-content: left;
    width: 100%;
    padding: $padding-big-element;
    background-color: $section-background-color;
    border-radius: $border-radius-big-element;
    margin: 0 5px 5px 5px;
    padding: 19px;
    @include plastic-shadow;

    li 
    {
        display: flex;
        line-height: 45px;
        margin-bottom: 7px;
        margin-left: 30px;
        
        .help-li-text
        {
            color: $paragraph-text-color;
            font-weight: bold;
        }
    }

    .help-li-text:hover
    {
        cursor: pointer;
        background-color: $active-elements-color;
        padding: 5px;
        border-radius: $border-radius-big-element;
        color: $section-background-color;
    }
}


.help-tip-window
{
    @include pop-up-window;
    padding-top: 30px;
    
    .cancel-button
    {
        @include pup-up-cancel-button;
    }
}

.help-feedback-window
{
    @include pop-up-window;
    
    .cancel-button
    {
        @include pup-up-cancel-button;
    }

    .help-feedback-isbug
    {
        display: flex;
        flex-direction: row;
        width: 300px;
        padding: 5px;
        margin-left: 10px;

        label
        {
            font-size: 20px;
            margin-left: 10px;
            text-align: center;
        }
    }

    .help-feedback-buttons
    {
        align-self: center;
        display: flex;
        justify-content: space-between;
        width: 300px;
        padding: 5px;

        button
        {
            background-color: $active-elements-color;
            padding: 10px;
            border-radius: $border-radius-big-element;
            width: 100px;
            color: $section-background-color;
        }

        button:hover
        {
            cursor: pointer;
        }
    }

    textarea
    {
        background-color: $page-background-color;
        padding: 15px;
        border-radius: $border-radius-big-element;
        margin: 10px;
        font-family: $font-family-common;
        color: $paragraph-text-color;
        width: 300px;
    }

    .help-feedback-header
    {
        width: 300px;
        text-align: center;

        .help-feedback-heading
        {
            font-size: 28px;
            font-weight: bold;
            color: $heading-text-color;
        }
    }

    #feedback-checkbox
    {
        width: 25px;
        cursor: pointer;
    }
}

.help-about-window
{
    @include pop-up-window;

    .help-about-header
    {
        display: flex;
        justify-content: space-between;

        #about-logo
        {
            max-width: 80%;
            margin-left: 40px;
        }
    }
    
    .cancel-button
    {
        min-width: 40px;
        max-width: 40px;
        display: flex;
        cursor: pointer;
    }

    p
    {
        overflow-y: scroll;
        max-height: 250px;
        padding: 15px;
        margin: 10px;
        background-color: $page-background-color;
        color: $paragraph-text-color;
        border-radius: $border-radius-big-element;
    }

    .help-about-links
    {
        display: flex;
        flex-direction: column;
        align-items: center;

        .help-about-link
        {
            padding: 7px;
            background-color: $active-elements-color;
            border-radius: $border-radius-big-element;
            margin-top: 5px;
            width: 200px;
            text-align: center;
            color: $section-background-color;
        }
    }
}