@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.plan-wrapper
{
    @include current-page-wrapper;
}

.plan-content
{
    @include current-page-content;
}

.plan-background-image
{
    @include current-page-background-image;
}

.plan-content-panels
{
    @include current-page-content-panels;
}

.plan-content-panel-1
{
    @include current-page-content-panel-element;
}

.plan-content-panel-2
{
    @include current-page-content-panel-element;
}

h1
{
    @include current-page-heading;
}

.plan-mementos-table
{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    background-color: $section-background-color;
    padding: $padding-big-element;
    border-radius: $border-radius-big-element;
    @include plastic-shadow;
}

.plan-no-mementos-p
{
    padding: 10px;
    background-color: $page-background-color;
    border-radius: $border-radius-big-element;
    color: $paragraph-text-color;
}