@import "../../../common/styles/colors.scss";

.message-block {
  position: relative;
  padding: 20px 50px;
  border-radius: 20px;
  margin-bottom: 20px;
}
  
.message-block-left {
  @extend .message-block;
  width: 380px;
  background: $complementary_color;
}

.message-block-right {
  @extend .message-block;
  // min-width: 380px;
  align-self: flex-end;
  background: $primary_color;
  color: $complementary_color;
}

.message-arrow {
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
  
.message-arrow-left {
  @extend .message-arrow;
  transform: translate(-100%, -50%);
  border-right: 10px solid $complementary_color;
  left: 0px;
}

.message-arrow-right {
  @extend .message-arrow;
  transform: translate(100%, -50%);
  border-left: 10px solid $primary_color;
  right: 0px;
}