@import "../../common/styles/colors.scss";
@import "../../common/styles/common.scss";

.badge-detail-form {
  @include pop-up-window;

  ul {
    margin-left: 20px;
    margin-top: 3px
  }

  form > p, form > h3, form > h2 {
    margin-top: 15px;
  }

  .detail-image {
    width: 80px;
    height: 80px;
    margin: 10px auto;
    display: block;
  }

  img {
    height: 30px;
    width: 30px;
  }

  .cancel-button {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 15px;
  }

  .badge-detail-title
  {
      font-size: 25px;
      font-weight: bold;
      overflow: auto;
  }

  h1 {
    font-size: 25px !important;
    margin-bottom: 0px !important;
  }

  h2 {
    font-size: 20px !important;
  }

  .badge-detail-headline {
    @include flex-direction-align(column, center);
    margin-bottom: 20px;
    
    img {
      margin-bottom: 10px;
      width: 60px;
      height: 60px;
    }
  }

  .badge-detail-footer {
    @include flex-direction-justify(row, space-between);
    margin-top: 20px;

    button {
      cursor: pointer;
      padding: 5px 10px;
      border-radius: $border-radius-big-element;
      margin-bottom: 20px;
      background-color: $active-elements-color;
      color: $section-background-color;
    }
  }

  .success-user-variables-input {
    border-radius: $border-radius-big-element;
    background-color: $page-background-color;
    width: 80px;
    padding: 5px;
    text-align: center;
  }

  textarea {
    font-size: 17px;
    font-family: $font-family-common;
  }
}