@import "../../../common/styles/common.scss";
@import "../../../common/styles/colors.scss";

.badgeitem-wrapper
{
    display: flex;
    flex-direction: column;
    align-items: center;

    .badgeitem-img
    {
        width: 60px;
        margin-bottom: 10px;
    }
    
    .badgeitem-title
    {
        font-size: 14px;
        text-align: center;
        color: $paragraph-text-color;
    }
}