@import "../../../common/styles/colors.scss";
@import "../../../common/styles/common.scss";


.reward-item
{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 50px;
}

.reward-item-link
{
    font-weight: 600;
}

.reward-item-link:hover
{
    background-color: $active-elements-color;
    border-radius: $border-radius-big-element;
    padding: 2px 5px 2px 5px;
    color: $section-background-color;
}

.reward-item-img
{
    width: 50px;
    height: auto;
    border-radius: $border-radius-big-element;
}

.reward-item-text-wrapper
{
    margin-left: 5px;
    color: $paragraph-text-color;
}

.reward-item-p
{
    font-size: 12px;
}