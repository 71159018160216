@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.navbar-wrapper {
  position: fixed;
  background-color: $section-background-color;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 10;
  border-bottom: 1px solid;
  border-color: black;
  display: flex;
  justify-content: space-between;
  font-size: 1.1vw;
  font-weight: bold;
  color: #333333;

    .navbar {
        height: 80px;
        width: 100%;
    }

    .navbar-home {
        width: 30vw;
        max-width: 200px;
    }

    .logo {
        height: 80px;
        width: 30vw;
        max-width: 200px;
    }

    .navbar-img {
        width: 30px;
        vertical-align: middle;
        margin-right: 3px;
    }

    .navbar {
        float: right;
        width: 50%;
    }

    .navbar-ul {
        display: inline-block;
        height: 80px;
        padding-right: 10px;
    }

    .navbar-li {
        display: inline;
        line-height: 80px;
        padding-right: 7px;
    }

    .navbar-chosen-item {
        padding: 7px;
        background-color: $active-elements-color;
        border-radius: $border-radius-big-element;
        color: $section-background-color;
    }
}

@media screen and (min-width: 1200px) {
    .navbar-wrapper {
       font-size: 14px;

       .navbar-img {
            width: 36px;
       }
    }
}

@media screen and (max-width: 650px) {
    .navbar-wrapper {
        font-size: 8px;
        justify-content: center;

        .navbar-img {
            width: 20px;
            margin-right: 1px;
        }

       .navbar-home {
        width: 0;
       }

       .logo {
        display: none;
       }
    }
}