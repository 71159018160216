@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.login-wrapper
{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $section-background-color;
    width: 400px;
    padding: $padding-big-element;
    border-radius: $border-radius-big-element;
    margin: 0 auto;
    @include plastic-shadow;

  .login-form
  {
      display: flex;
      flex-direction: column;

      .login-form-input
      {
          padding: $padding-big-element;
          border-radius: $border-radius-big-element;
          background-color: $page-background-color;
          margin-bottom: 20px; 
      }

      .login-form-label
      {
          padding: 0 10px 0 10px;
      }

      .login-form-button
      {
          padding: $padding-big-element;
          border-radius: $border-radius-big-element;
          background-color: $active-elements-color;
          color: $section-background-color;
          text-align: center;
          cursor: pointer;
          margin-bottom: 5px;
      }
  }

  #wrong
  {
      color: red;
  }

  #right
  {
      color: greenyellow;
  }

  .registration-success-text
  {
      text-align: center;
      color: $paragraph-text-color;
      padding: $padding-big-element;
  }
}