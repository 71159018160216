@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";
  
.appointment {
  @include flex-direction(column);
  //background: $primary_color_lighter;
  height: 100%;
  padding-top: 150px;
  padding-bottom: 20px;
  
  nav {
    flex: 0 1;
  }
}

// NEW -------------------------------

.appointment-wrapper
{
  @include current-page-wrapper;
  color: $paragraph-text-color;
}

.appointment-content
{
  @include current-page-content;
  width: 50%;
  max-width: 1000px;
  min-width: 500px;
  padding-top: 35px;
  text-align: center;
  align-items: center;
  background-color: $section-background-color;
  @include plastic-shadow;

  .payment-background-image, .appointment-intro-background-image
  {
    width: 100%;
    margin-bottom: 20px;
  }

  h1
  {
    margin-bottom: 15px;
    color: $heading-text-color;
    max-width: 90%;
  }

  h2
  {
    margin-bottom: 10px;
    color: $heading-text-color;
  }

  h3
  {
    margin: 10px 0;
    color: $heading-text-color;
  }

  p
  {
    max-width: 90%;
    margin: 10px auto;
    color: $paragraph-text-color;
  }

  .appointment-intro-back-home-button
  {
    padding: 10px;
    border-radius: $border-radius-big-element;
    background-color: $active-elements-color;
    margin: 10px auto;
    color: $section-background-color;
  }
}

.payment-icons-list
{
  list-style: none;
  display: grid;
  width: 70%;
  padding: 5px 5px 5px 3vw;
  background-color: $page-background-color;
  border-radius: $border-radius-big-element;

  .payment-icon
  {
    display: flex;
    line-height: 60px;
    margin-bottom: 5px;

    .payment-icons-list-img
    {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    .payment-icons-list-button
    {
      height: 25px;
      align-self: center;
      color: $paragraph-text-color;
    }

    .payment-icons-list-button:hover
    {
      cursor: pointer;
      background-color: $active-elements-color;
      padding: 0 10px;
      border-radius: $border-radius-big-element;
      color: $section-background-color;
    }

    .payment-icons-list-link
    {
      display: block;
      line-height: 25px;
      height: 25px;
      align-self: center;
    }

    .payment-icons-list-link:hover
    {
      background-color: $active-elements-color;
      padding: 0 10px;
      border-radius: $border-radius-big-element;
      color: $section-background-color;
    }
  }
}

.intro-start-session-button
{
  display: block;
  margin-top: 20px;
  background-color: $active-elements-color;
  color: $section-background-color;
  border-radius: $border-radius-big-element;
  align-self: center;
  padding: 10px;
  cursor: pointer;
}

// NEW -------------------------------

/*
.appointment-container {
  position: relative;
  @include flex-direction-align-justify(row, center, space-evenly);
  height: 70%;
  width: 70%;
  background-color: $complementary_color;
  border-radius: 15px;
  //margin: 7% auto 0px auto;
  margin: 0 auto;
}
*/

.appointment-loading {
  @include flex-direction-align-justify(column, center, center);
  width: 100%;
  height: 100%;
  // position: fixed;
  position: absolute;
  background-color:rgba(black, 0.5);
  color: white;
  font-size: 40px;
  z-index: 999999;
  top: 0;
  left: 0;
}

.appointment-background-picture {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-60%);
  width: 300px;
  z-index: 0;
}

/*
.appointment-text-container {
  position: relative;
  @include flex-direction(column);
  overflow-y: auto;
  height: 100%;
  width: 82%;
  background-color: $primary_color;
  padding: 20px 10% 0pc 10%;
  color: $complementary_color;
  font-size: 35px;
}
*/

.appointment-arrow {
  width: 60px;
}

.appointment-arrow-container {
  @include flex-direction-align(column, center);
}

.appointment-arrow-description {
  font-size: 20px;
}

/*
.landing-image {
  height: 55%;
  border-radius: 10px;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
*/

/*
.start-session-button {
  margin-top: 20px;
  background-color: $complementary_color;
  color: $primary_color;
  border-radius: 8px;
  padding: 5px 22px;
  align-self: center;
  cursor: pointer;
}
*/

.back-home-button {
  margin-top: 20px;
  background-color: $complementary_color;
  color: $primary_color;
  border-radius: 8px;
  padding: 5px 22px;
  align-self: center;
  cursor: pointer;
}

.debug-session-area {
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: lightgray;
  padding: 10px;
  color: black;

  .debug-session-checkpoint {
    display: block;
    cursor: pointer;
    padding: 5px;
    margin-bottom: 5px;
  }

  .debug-session-checkpoint-chosen {
    background-color: $primary_color;
    color: $complementary_color;
  }
}

.debug-session-button-area {
  @include flex-direction-justify(row, space-between);
  margin-top: 5px;

  button {
    background-color: $complementary_color;
    color: $primary_color;
    border-radius: 8px;
    padding: 5px 22px;
    align-self: center;
    cursor: pointer;
  }
}

/*
.payment-icons-container {
  @include flex-direction-align(row, center);
  margin-top: 30px;
  font-size: 25px;

  button, a {
    margin-left: 20px;
    color: white;
    cursor: pointer;
  }
}

.payment-icons {
  width: 60px;
}
*/

.payment-argument-form {
  @include pop-up-window;

  .multiplechoice {
    margin-bottom: 0px;
  }

  label {
    font-size: 16px;
    text-align: left;
  }

  textarea {
    width: 70%;
    background-color: $page-background-color;
    resize: none;
    border-radius: $border-radius-big-element;
    padding: 10px;
    margin-top: 5px;
  }

  .hidden {
    display: hidden;
    padding: 0 !important;
  }

  .none {
    display: none !important;
  }

  div {
    text-align: center;
    font-size: $font-size-h2;
  }

  h2 {
    text-align: center;
    font-size: $font-size-h2;
    width: 85%;
  }

  .feedback-validation {
    font-size: 15px;
    color: red;
  }

  .payment-argument-content {
    @include flex-direction-align(column, center);

  }

  .payment-argument-form-text {
    margin-bottom: 50px;
    font-size: 16px;
    color: $paragraph-text-color;
  }

  .payment-argument-pay-button {
    padding: 7px 20px;
    cursor: pointer;
    background-color: $active-elements-color;
    border-radius: 20px;
    color: white;
    font-weight: bold;
    font-size: 20px;
  }

  .payment-argument-control-area {
    @include flex-direction-justify(row, space-between);
    width: 100%;
    font-weight: bold;
    margin-top: 30px;
    
    button {
      padding: 10px;
      background-color: $page-background-color;
      border-radius: $border-radius-big-element;
      font-size: 16px;
    }
  }

  button, a {
    cursor: pointer;
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  img {
    height: 150px;
    width: 150px;
  }

  .cancel-button {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;

    img {
      height: 30px;
      width: 30px;
    }
  }
}

.payment-form-special-code {
  @include pop-up-window;

  .cancel-button {
    @include pup-up-cancel-button;
  }

  h2 {
    font-size: $font-size-h2;
    width: 90%;
    margin-top: 15px;
    text-align: center;
  }

  input {
    background-color: $page-background-color;
    color: $paragraph-text-color;
    margin-top: 20px;
    padding: 10px;
    border-radius: $border-radius-big-element;
    font-size: 20px;
    width: 80%;
    align-self: center;
  }

  .special-code-ok {
    align-self: center;
    margin-top: 40px;
    margin-bottom: 10px;
    background-color: $active-elements-color;
    color: $section-background-color;
    padding: 6px 25px;
    border-radius: $border-radius-big-element;
    cursor: pointer;
  }
}

.pending-reply-notification {
  font-size: 20px;

  .button-container {
    @include flex-direction-align(row, center);
    margin-top: 10px;
    color: $complementary_color;
    cursor: pointer;
  }

  .reply-notification-img {
    width: 40px;
    margin-right: 15px;
  }
}

.appointment-final-goodbye {
  z-index: 100;  
  position: fixed;
  align-items: center;
  background-color: white;
  color: black;
  padding: 20px 35px;
  top: 50%;
  left: 50%;
  margin-left: -300px;
  transform: translateY(-55%);
  max-width: 600px;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 17px;

  .appointment-final-goodbye-content {
    @include flex-direction-align(column, center);
  }

  .final-goodbye-next-button {
    padding: 6px 30px;
    background-color: $primary_color;
    color: $complementary_color;
    border-radius: 10px;
    margin-top: 25px;
    cursor: pointer;
  }

  img {
    width: 80%;
    height: auto;
  }

  .cancel-button {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;

    img {
      height: 30px;
      width: 30px;
    }
  }
}