@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.feedback-wrapper
{
    display: flex;
    flex-direction: row;
    background-color: $active-elements-color;
    border-radius: $border-radius-big-element;
    max-width: 200px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0 5px 5px 0;

    .feedback-button
    {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px;

        .feedback-button-title
        {
            color: $section-background-color;
        }

        .feedback-button-img
        {
            width: 35px;
            margin: 0 5px 0 5px;
        }
    }
}

.feedback-formular-window
{
    @include pop-up-window;
    align-items: flex-end;

    .formular-iframe
    {
        width: 400px;
        height: 460px;
    }

    .cancel-button-img
    {
        width: 35px;
    }
}