@import "../../../common/styles/common.scss";
@import "../../../common/styles/colors.scss";

.statisticItem-row
{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 5px;

    .statisticItem-img
    {
        width: 45px;
        margin-right: 10px;
    }

    .statisticItem-title
    {
        margin-right: 5px;
        color: $paragraph-text-color;
    }

    .statisticItem-value
    {
        color: $paragraph-text-color;
    }
}
