@import "../../common/styles/colors.scss";
@import "../../common/styles/common.scss";

.indicator-wrapper
{
    background-color: $section-background-color;
    text-align: center;
    border-radius: $border-radius-big-element;
    width: 100%;
    display: flex;
    align-items: center;

    .indicator-title
    {
        font-size: 18px;
        padding: 0 10px 0 10px;
        cursor: default;
        font-weight: bold;
        color: $paragraph-text-color;
    }

    .indicator-input::-webkit-slider-runnable-track
    {
        background: linear-gradient(90deg, rgba(226,29,29,1) 0%, rgba(228,225,63,1) 50%, rgba(166,255,0,1) 100%);
        border-radius: 5px;
        cursor: default;
    }

    .indicator-input::-webkit-slider-thumb
    {
        background: $section-background-color;
        border: 1px solid black;
        cursor: default;
    }
}