@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.profil-name-wrapper
{
    background-color: $section-background-color;
    border-radius: $border-radius-big-element;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 0 10px 0;
    width: 100%;
    margin-bottom: 15px;
    @include plastic-shadow;
}

.profil-name-column-1
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profil-name-input
{
    background-color: $page-background-color;
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: $border-radius-big-element;
}

.profil-name-button
{
    background-color: $active-elements-color;
    border-radius: $border-radius-big-element;
    padding: 7px;
    font-size: 14px;
    margin-top: 5px;
    color: $section-background-color;
    width: 70px;
}

.profil-name-button:hover
{
    cursor: pointer;
}

.profil-name-img
{
    width: 100px;
}

.success-message
{
    font-size: 12px;
    color: $active-elements-color;
}

.validation-message
{
    font-size: 12px;
    color: red;
}

.profil-name-title
{
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    cursor: default;
}