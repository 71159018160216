@import "../../common/styles/common.scss";
@import "../../common/styles/colors.scss";

.success-wrapper
{
    @include current-page-wrapper;
}

.success-content
{
    @include current-page-content;
}

.success-content-panels
{
    @include current-page-content-panels;
}

.success-content-panel-1
{
    @include current-page-content-panel-element;
}

.success-content-panel-2
{
    @include current-page-content-panel-element;
}

@mixin success-wrapper-description
{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $section-background-color;
    padding: 10px;
    border-radius: $border-radius-big-element;
    height: 370px;
    margin-bottom: 15px;
    @include plastic-shadow;

    .success-description-title
    {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .success-description-img
    {
        width: 60%;
        border-radius: $border-radius-big-element;
    } 
}

.success-statistics-wrapper-description, .success-rewards-wrapper-description, .success-badges-wrapper-description
{
    @include success-wrapper-description;
}

.success-statistics-wrapper
{
    display: flex;
    flex-direction: column;
    width: 480px;
    background-color: $section-background-color;
    padding: 10px;
    border-radius: $border-radius-big-element;
    margin-bottom: 15px;
    height: 370px;
    @include plastic-shadow;

    .success-statistics-wrapper-title
    {
        font-size: 26px;
        font-weight: bold;
        margin: 10px 0 40px 100px;
    }
}

.badges-wrapper
{
    display: flex;
    flex-direction: column;
    background: $section-background-color;
    padding: 10px;
    border-radius: $border-radius-big-element;
    align-items: center;
    justify-content: center;
    width: 480px;
    margin-bottom: 15px;
    height: 177.5px;
    @include plastic-shadow;
    
    .badges-title
    {
        display: block;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 10px;
    }
}

/*
.success {
  @include flex-direction(column);
  //background: linear-gradient($primary_color_lightest, $primary_color);
  padding-bottom: 70px;
  //V
  max-width: 100%;
}

.success-container {
  @include flex-direction-align-justify(row, stretch, center);
  position: relative;
  margin-top: 40px;

  &:last-child {
    margin-top: 70px;
  }
}

.success-content {
  @include flex-direction-justify(column, center);
  //min-width: 400px;
  //V
  width: 25%;
}

.success-header {
  background-color: $primary_color;
  color: $complementary_color;
  border-radius: 10px;
  border: 1px solid $complementary_color_darkest;
  padding: 7px 15px;
  margin-bottom: 25px;
}

.success-fact-title {
  margin-top: 10px;
  margin-bottom: 10px;
  //Vojta
  text-align: center;
}

.success-fact-description {
  font-size: 13px;
  font-style: italic;
}

.success-table {
  border: 1px solid $complementary_color_darkest;
  padding: 15px 10px;
  height: 280px;
  overflow-y: auto;
}

.success-table-record {
  @include flex-direction-align(row, center);
  margin-bottom: 10px;
  margin-right: 50px;
  
  &:last-child {
    margin-bottom: 0px;
  }

  img {
    width: 35px;
    height: auto;
  }
}

.success-table-record-description {
  font-style: italic;
  margin-left: 5px;
  margin-right: 0px;
  width: 58%;
  white-space: nowrap;
  font-size: 13px;
  display: block;
  
}

.success-table-record-value {
  font-weight: bold;
  margin-top: 35px;
  display: block;
  margin-right: 50%;
}

.success-vertical-line {
  border: 1px solid $complementary_color_darkest;
  margin: 0px 50px;
}

.success-description {
  @include flex-direction-align(column, center);
  width: 25%;

  &> img {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
}

.success-description-title {
  margin-bottom: 20px;
}

.success-description-text {
  text-align: justify;
  font-size: 22px;
  margin-bottom: 20px;

  img {
    margin-right: 5px;
    width: 18px;
    height: 18px
  }
}

.success-badge-header {
  @extend .success-header;
  @include flex-direction(column);
}

.success-badge-title {
  align-self: center;
}

.success-badge-description {
  opacity: 0.5;
}

.success-horizontal-line {
  position: absolute;
  bottom: -22.5px;
  width: 55%;
  border: 1px solid $complementary_color_darkest;
}

.success-badge-no-more-badge {
  text-align: center;
  margin: 10px 0px;
}

.badge-detail-form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -300px;
  transform: translateY(-53%);
  max-width: 650px;
  padding: 0 70px;
  max-height: 90%;
  min-height: 200px;
  z-index: 999;

  .arrow {
    cursor: pointer;
    position: absolute;
    background: white;
    border-radius: 20px;
    border: 2px solid black;
    background-color: lighten(lightgray, 12);
    top: 48%;

    img {
      width: 55px;
      padding: 10px;
    }
  }

  .left-arrow {
    @extend .arrow;
    left: 5px;
    transform: rotate(-90deg)
  }

  .right-arrow {
    @extend .arrow;
    right: 5px;
    transform: rotate(90deg)
  }
}

.badge-detail-form {
  position: relative;
  background-color: white;
  padding: 25px 25px;
  padding-bottom: 0px;
  max-width: 650px;
  border: 1px solid black;
  border-radius: 10px;
  max-height: 100%;
  z-index: 999;
  overflow-y: auto;

  ul {
    margin-left: 20px;
    margin-top: 3px
  }

  form > p, form > h3, form > h2 {
    margin-top: 15px;
  }

  .detail-image {
    width: 80px;
    height: 80px;
    margin: 10px auto;
    display: block;
  }

  img {
    height: 30px;
    width: 30px;
  }

  .cancel-button {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 15px;
  }

  h1 {
    font-size: 25px !important;
    margin-bottom: 0px !important;
  }

  h2 {
    font-size: 20px !important;
  }

  .badge-detail-headline {
    @include flex-direction-align(row, center);
    margin-bottom: 20px;
    
    img {
      margin-right: 10px;
    }
  }

  .badge-detail-footer {
    @include flex-direction-justify(row, space-between);
    margin-top: 20px;

    button {
      cursor: pointer;
      padding: 5px 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: $complementary_color_darker;
    }
  }

  .success-user-variables-input {
    border-radius: 5px;
    background-color: $complementary_color_darker;
    width: 80px;
    padding: 5px;
  }

  textarea {
    font-size: 17px;
  }
}

//Vojta
.success-reward-title
{
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.success-badge-description
{
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
*/